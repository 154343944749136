import { Link } from "react-router-dom";


function BlogCard({ title, description, link, image, handleFooterLink }) {
  return (
 
    <div className="col-12 col-lg-4 col-md-6 mb-4">
      <Link to={link} onClick={handleFooterLink} className="text-white text-decoration-none">
        <div className="card shadow p-3 mb-5 bg-white rounded">
          <img src={image} className="card-img-top" alt={title} style={{"object-fit": "cover", "width": "100%", "height": "15rem" }} />
          <div className="card-body">
            <h5 className="card-title mt-2">{title}</h5>
            <p className="card-text">{description}</p>
          </div>
        </div>
      </Link>
    </div>

  );
}

export default BlogCard;
