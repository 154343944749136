import React, { useState } from "react";
import Styled from "styled-components";
import fbg from "../components/images/fbg.webp";
import Finalnav from "./Homepages/Finalnav";
import Footer from "./Homepages/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";

function Faq() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>FAQ - Software Development Company|Best SEO Company</title>
        <meta
          name="description"
          content="At DOAGuru InfoSystems, we offer the best full-stack website designing and development services in Jabalpur."
        />
        <link rel="canonical" href="https://doaguru.com/faq" />
      </Helmet>

      <Container>
        <Breadcrumb />

        <div className="card bg-dark text-white text-center">
          <img
            className="card-img"
            src={fbg}
            alt="Card image"
            style={{ height: "27rem" }}
          />
          <div className="card-img-overlay d-flex  flex-column justify-content-center">
            <h1 className="card-title" style={{ marginTop: "17rem" }} id="loo">
              LOOKING FOR AN ANSWER?
            </h1>
            <p className="card-text text-white">
              The frequently asked questions we were asked along the way.
            </p>
          </div>
        </div>

        <div className="container text-center mt-4 ">
          <h2 style={{ marginLeft: "10px" }}>Need Help ?</h2>
          <h1 style={{ textAlign: "center", marginTop: "15px" }}>FAQ'S</h1>
        </div>

        <div className="container">
          <p>
            <a
              className="btn btn-light my-3 btn-lg btn-block shadow-lg  border align-items-left justify-content-left text-left"
              data-bs-toggle="collapse"
              href="#collapse01"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Why should I choose DoaGuru Software Services for Web Development
              Services?
            </a>
          </p>
          <div className="collapse" id="collapse01">
            <div className="card card-body">
              <p>
                DoaGuru Software is one of the best web development service
                providers In India.Our web design services include conceiving,
                creating, and producing your website as well as providing
                ongoing maintenance and support. Web design, eCommerce
                development, software and application development, graphic
                design, and online marketing services are our primary fields of
                expertise.
              </p>
            </div>
          </div>
        </div>

        <div className="container ">
          <p>
            <a
              className="btn btn-light my-3 btn-lg btn-block shadow-lg  border align-items-left justify-content-left text-left"
              data-bs-toggle="collapse"
              href="#collapse02"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Does DoaGuru offer Compatibility for all Search Engines?
            </a>
          </p>
          <div className="collapse" id="collapse02">
            <div className="card card-body">
              Yes, We offer website compatibility for Search Engines. We create
              your website as the best web design business with the goal of
              making it consistently visible across all web browsers. According
              to your company's needs, our expert design and development team
              develops top-notch websites.
            </div>
          </div>
        </div>
        <div className="container ">
          <p>
            <a
              className="btn btn-light my-3 btn-lg btn-block shadow-lg  border align-items-left justify-content-left text-left"
              data-bs-toggle="collapse"
              href="#collapse03"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Why choose web development companies in India?
            </a>
          </p>
          <div className="collapse" id="collapse03">
            <div className="card card-body">
              When it comes to outputs and services, web design and development
              in India is a very cost-effective option. The benefits of hiring
              the best digital marketing company for your online business in
              India are.
            </div>
          </div>
        </div>
        <div className="container">
          <p>
            <a
              className="btn btn-light my-3 btn-lg btn-block shadow-lg  border align-items-left justify-content-left text-left"
              data-bs-toggle="collapse"
              href="#collapse04"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Are your web apps mobile-friendly?
            </a>
          </p>
          <div className="collapse" id="collapse04">
            <div className="card card-body">
              Absolutely yes! In the realm of web development, we ensure the
              seamless adaptability of our creations across a myriad of mobile
              devices. Tailoring our designs to accommodate varying screen
              dimensions and orientations, our esteemed web development
              establishment situated in India proffers a repertoire of
              responsive website architectures, compatible with a diverse array
              of technological apparatus.
            </div>
          </div>
        </div>
        <div className="container">
          <p>
            <a
              className="btn btn-light my-3 btn-lg btn-block shadow-lg  border align-items-left justify-content-left text-left"
              data-bs-toggle="collapse"
              href="#collapse05"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Does DoaGuru make custom-designed Websites?
            </a>
          </p>
          <div className="collapse" id="collapse05">
            <div className="card card-body">
              According to your requirements and services, we offer specialized
              services for your website. Our Design and Development team, backed
              by the expertise of the Best IT Company, creates Customized Web
              Development Services for you from scratch which is easy to manage
              and Collaborate with a digital strategist to aid with your
              website's success in helping you reach your desired objective.
            </div>
          </div>
        </div>
        <div className="container">
          <p>
            <a
              className="btn btn-light my-3 btn-lg btn-block shadow-lg  border align-items-left justify-content-left text-left"
              data-bs-toggle="collapse"
              href="#collapse06"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Will you provide a full-time dedicated project manager?
            </a>
          </p>
          <div className="collapse" id="collapse06">
            <div className="card card-body">
              Yes, we will assign a project manager specifically for your
              project to you. Additionally, the manager updates you on the
              status of your project on a frequent basis. You may also provide
              this person with comments or a suggestion. He will oversee the
              project in accordance with your suggestions.
            </div>
          </div>
        </div>
        <div className="container">
          <p>
            <a
              className="btn btn-light my-3 btn-lg btn-block shadow-lg border align-items-left justify-content-left text-left"
              data-bs-toggle="collapse"
              href="#collapse07"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Should I create a mobile or a web app?
            </a>
          </p>
          <div className="collapse" id="collapse07">
            <div className="card card-body">
              Both have their benefits and flaws. Mobile apps are undoubtedly
              more expensive than online applications, but they also provide
              much better data collection and revenue potential.
            </div>
          </div>
        </div>
        <Footer />
      </Container>
    </>
  );
}
export default Faq;

const Container = Styled.div`

margin-bottom:2rem;
 

  #bgc{
    background:#dde7f5;  
 
  }
  @media only screen and (max-width: 468px) {

    mainText{
        font-size : 12px;
    }
}
#loo{
  text-shadow: 0 1px 0 #ccc,
               0 2px 0 #c9c9c9,
               0 3px 0 #bbb,
               0 4px 0 #b9b9b9,
               0 5px 0 #aaa,
               0 6px 1px rgba(0,0,0,.1),
               0 0 5px rgba(0,0,0,.1),
               0 1px 3px rgba(0,0,0,.3),
               0 3px 5px rgba(0,0,0,.2),
               0 5px 10px rgba(0,0,0,.25),
               0 10px 10px rgba(0,0,0,.2),
               0 20px 20px rgba(0,0,0,.15);


}
h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
   
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
   
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size:1.2rem;
   
    }
  }
`;
