import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import blog10 from "../images/blog10.webp";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";
import { Helmet } from "react-helmet";

function Blog10() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Helmet>
      <title>
      Top IT Company in Jabalpur

        </title>
        <meta
          name="description"
          content="Discover the best IT companies in Jabalpur with our comprehensive guide. Find top-rated IT companies offering innovative solutions and services in Jabalpur. Stay ahead with the leading IT company in the region."
        />
        <link rel="canonical" href="https://doaguru.com/The-Best-IT-Company-in-Jabalpur-DOAGuru-Infosystems" />
        {/* <Link rel="canonical" to="https://doaguru.com/Best-Software-Development-Company-in-Jabalpur" /> */}

      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
            The Best IT Company in Jabalpur - DOAGuru InfoSystems

            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={blog10} alt="" className="frontimg rounded" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                  In the bustling realm of Information Technology within Jabalpur, there’s one name that resonates with excellence and innovation - <Link
                      to="https://doaguru.com/"
                      target="_blank"
                      className="text-decoration-none"
                    >DOAGuru InfoSystems</Link>. Established with a vision to redefine software solutions, DOAGuru has solidified its position as the <Link
                    to="https://doaguru.com/"
                    target="_blank"
                    className="text-decoration-none"
                  >leading IT company in Jabalpur</Link>.

                  </p>
                </div>
              </div>

          
              {isShowMore && (
                <p>
                  <h3>
                  Why Choose DOAGuru InfoSystems?

                  </h3>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h6>At DOAGuru InfoSystems, excellence is not just a goal; it’s a standard we live by. Here’s why we stand out as the best IT company in Jabalpur:
</h6>
                      <ul>
                        <li> SEO strategies are not one size fits all. DOAGuru
                        Infosystems takes the time to understand your business
                        target audience and industry dynamics to create
                        customized SEO plans that produce results.</li>
                        <li>Client-Centric Approach: We prioritize our clients’ success. By understanding their unique requirements, we ensure that our solutions are not just innovative but also aligned with their business goals.</li>
                        <li>Commitment to Quality: Quality is at the core of everything we do. We adhere to stringent quality standards to deliver robust, scalable, and secure solutions that exceed expectations.
</li>
                       

                      </ul>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>Services Offered :- DOAGuru InfoSystems offers a comprehensive suite of IT services, including:

</h5>
<ul>
                        <li> Custom Software Development: Transform your ideas into reality with our custom software development services.
</li>
                        <li>Web and Mobile App Development: Engage your audience with intuitive and responsive web and mobile applications.
</li>
                        <li>Enterprise Solutions: Streamline your business operations with our tailored enterprise solutions.

</li>
                       
                        <li>Digital Marketing: Amplify your online presence and drive growth through strategic digital marketing services.


</li>
                       

                      </ul>
                   
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>Our Success Stories:
:</h5>
                      <p>
                      Over the years, DOAGuru InfoSystems has partnered with numerous businesses and organizations in Jabalpur, delivering impactful IT solutions that drive growth and efficiency. Our success stories speak volumes about our expertise and commitment.

                        
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>Experience the DOAGuru Advantage:
</h5>
                      <p>
                      Ready to elevate your business with the best IT company in Jabalpur? Contact DOAGuru InfoSystems today for innovative IT solutions that propel your business forward. Discover why we are trusted by businesses across Jabalpur and beyond.

                      </p>
                      <p>Remember, when you think of the <Link
                      to="https://doaguru.com/"
                      target="_blank"
                      className="text-decoration-none"
                    >Best IT company in Jabalpur</Link> , think DOAGuru InfoSystems!
</p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h5>Comprehensive Offerings:</h5>
                      <p>
                        From researching keywords and optimizing, on-page
                        content to creating engaging material and building
                        quality links DOAGuru InfoSystems provides a range of{" "}
                        <Link
                          to="/seo"
                          target="_blank"
                          className="text-decoration-none"
                        >
                          SEO services
                        </Link>{" "}
                        to enhance all aspects of your presence.
                      </p>
                      <p>
                        If you're looking to boost your presence and stand out
                        in the world consider partnering, with DOAGuru
                        Infosystems,{" "}
                        <Link
                          to="/seo"
                          target="_blank"
                          className="text-decoration-none"
                        >
                          {" "}
                          the best SEO Company in Jabalpur
                        </Link>
                        . Their team's knowledge, passion, and dedication to
                        your success make reaching top search engine rankings
                        and increasing traffic more than a dream – it's a
                        tangible goal waiting to be achieved. Reach out to
                        DOAGuru InfoSystems today. Start your path, toward SEO
                        success!
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>FAQ</h3>
                      <ul>
                        <li className="h6">
                        1. What makes DOAGuru InfoSystems the best IT company in Jabalpur?

                        </li>
                      </ul>
                      <p>
                      At DOAGuru InfoSystems, we differentiate ourselves through our commitment to excellence and innovation. Our team leverages cutting-edge technologies and a client-centric approach to deliver customized IT solutions that align with our clients' goals. We are proud to be recognized as the <Link
                      to="https://doaguru.com/"
                      target="_blank"
                      className="text-decoration-none"
                    >best IT company in Jabalpur</Link> due to our relentless pursuit of quality and customer satisfaction.

                      </p>
                      <ul>
                        <li className="h6">
                        2. What services does DOAGuru InfoSystems offer?

                        </li>
                      </ul>
                      <p>
                      DOAGuru InfoSystems offers a comprehensive range of IT services, including custom software development, web and mobile app development, enterprise solutions, and digital marketing services. Whether you need a bespoke software solution or want to enhance your digital presence, we have the expertise to deliver exceptional results.

                      </p>
                      <ul>
                        <li className="h6">
                        3. How does DOAGuru InfoSystems ensure the quality of its solutions?

                        </li>
                      </ul>
                      <p>
                      Quality is at the heart of everything we do at <Link
                      to="https://doaguru.com/"
                      target="_blank"
                      className="text-decoration-none"
                    >DOAGuru InfoSystems</Link>. We adhere to stringent quality standards throughout the development lifecycle, conducting thorough testing and validation to ensure our solutions are robust, scalable, and secure. Our commitment to quality is what sets us apart as the best IT company in Jabalpur.

                      </p>
                      <ul>
                        <li className="h6">
                        4. How can I benefit from partnering with DOAGuru InfoSystems?

                        </li>
                      </ul>
                      <p>
                      Partnering with <Link
                      to="https://doaguru.com/"
                      target="_blank"
                      className="text-decoration-none"
                    >DOAGuru InfoSystems </Link>opens doors to innovative IT solutions that drive business growth. Whether you're looking to streamline operations, reach new customers through digital marketing, or launch a cutting-edge mobile app, our team is dedicated to helping you achieve your objectives. Experience the DOAGuru advantage and unlock the full potential of your business.

                      </p>
                    </div>
                    <hr />
                  </div>
                </p>
              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read on"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Blog10;
const Wrapper = styled.div`
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
`;
