import React from "react";
import styled from "styled-components";
import react1 from "../images/react1.webp";
import angular1 from "../images/angular1.webp";
import flutter1 from "../images/flutter1.webp";
import php1 from "../images/php1.webp";
import python1 from "../images/phthon1.webp";
import wp1 from "../images/wp1.webp";
import jquery1 from "../images/jquery1.webp";
import html1 from "../images/html1.webp";
import css1 from "../images/css1.webp";
import js1 from "../images/js1.webp";
import bootstrap1 from "../images/Bootstrap1.webp";
import my1 from "../images/my1.webp";
import Finalnav from "../../page/Homepages/Finalnav";
import bgweb from "../images/websitebg.webp";
import webhero from "../images/webhero.webp";
import process1 from "../images/process1.webp";
import process2 from "../images/process2.webp";
import process3 from "../images/process3.webp";
import process4 from "../images/process4.webp";
import process5 from "../images/process5.webp";
import process6 from "../images/process6.webp";
import website_stramling from "../images/website_stramling.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumb from "../Breadcrumb";
import Footer from "../../page/Homepages/Footer";

function Web() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Finalnav />
      <Helmet>
        <title>Top Software Company in Jabalpur

        </title>
        <meta
          name="description"
          content="Discover DOAGuru InfoSystems, the Top Software Company in Jabalpur, offering the Best in software solutions and IT services. Experience excellence with our expert team and innovative technologies."
        />
        <link rel="canonical" href="https://doaguru.com/Top-Software-Company-in-Jabalpur" />
      </Helmet>
      <Wrapper>
        <Breadcrumb />
        <div
          className="container-fluid"
          id="cont"
          style={{
            backgroundImage: `url(${bgweb})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row1">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "5rem" }}>
              <h1 className="ws text-white" style={{ marginBottom: "1rem" }}>
              Top Software Company in Jabalpur

              </h1>
{/* 
              <h5 className="hdd text-white">
                "We Make Your Website Work for You"
              </h5> */}
              <p className="pdd text-white mt-4" style={{lineHeight:"1.9rem"}}>
              Welcome to DOAGuru InfoSystems, a leading Software Development Company in Jabalpur and one of the Top Software Development Companies in Jabalpur. We're committed to creating innovative and advanced solutions for clients globally. Our primary goal is to ensure quality, and customer satisfaction, and stay at the forefront of technology. With a strong focus on quality, customer satisfaction, and technological advancement, we stand out as the best software company in Jabalpur.

              </p>
              <button
                type="button"
                className="btn  mt-4"
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12" id="wwwhero">
              <img
                src={webhero}
                className="img3 d-block w-75"
                alt="..."
                id="webheropage"
              />
            </div>
          </div>
        </div>

        <div className="container mt-5 text-center ">
          <div className="hdd">
            <h2>Doaguru IT Solutions' Innovative Web Development Services</h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 20,
                marginBottom: 20,
              }}
            ></div>
          </div>

          <div className="row g-3">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img
                    src={process1}
                    className="m-auto"
                    style={{ height: "5rem", width: "5rem" }}
                  />
                  <div className="card body" id="webcard12">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Engaging Graphical Interface
                    </h5>
                    <div className="underline"></div>
                    <p style={{ textAlign: "center", padding: "15px" }}>
                      When seeking the expertise of the best digital marketing
                      company and top IT companies, you can anticipate a
                      seamless amalgamation of these components to construct a
                      captivating and user-friendly encounter. In the realm of
                      digital interaction, end-users forge a visual connection
                      with a website's graphical facade.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img
                    src={process2}
                    className="m-auto mt-3"
                    style={{ height: "4rem", width: "7rem" }}
                  />
                  <div className="card body" id="webcard12">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Bootstrapping:
                    </h5>
                    <div className="underline"></div>
                    <p style={{ textAlign: "center", padding: "15px" }}>
                      In the realm of web development, the term "bootstrapping"
                      denotes the adoption of a front-end framework such as
                      Bootstrap. This renowned framework encompasses HTML, CSS,
                      and JavaScript in its arsenal.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img
                    src={process3}
                    className="m-auto mt-3"
                    style={{ height: "4rem", width: "5rem" }}
                  />
                  <div className="card body" id="webcard12">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Screen Optimization:
                    </h5>
                    <div className="underline"></div>
                    <p style={{ textAlign: "center", padding: "15px" }}>
                      Responsive design is the practice of conceptualizing and
                      coding a website in a way that it seamlessly adapts to
                      diverse screen sizes and devices, encompassing desktops,
                      laptops, tablets, and smartphones. It stands as an
                      indispensable facet of the services dispensed by an
                      esteemed website development company.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img
                    src={process4}
                    className="m-auto mt-2"
                    style={{ height: "4.5rem", width: "5rem" }}
                  />
                  <div className="card body" id="webcard12">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Content Update:
                    </h5>
                    <div className="underline"></div>
                    <p style={{ textAlign: "center", padding: "15px" }}>
                      Opting for the best website design company is pivotal to
                      ensure that your online presence remains dynamic and
                      captivating. The content of a website necessitates
                      periodic rejuvenation and modification. This may entail
                      the publication of fresh blog entries, updates to product
                      particulars, the inclusion of new visuals, and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img
                    src={process5}
                    className="m-auto mt-3"
                    style={{ height: "4rem", width: "5rem" }}
                  />
                  <div className="card body" id="webcard12">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Application Security:
                    </h5>
                    <div className="underline"></div>
                    <p style={{ textAlign: "center", padding: "15px" }}>
                      The implementation of protective measures to shield a
                      website or web application from malevolent assaults,
                      unauthorized access, and data breaches is designated as
                      application security. This encompasses procedures such as
                      input validation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card shadow">
                <div data-aos="flip-up">
                  <img
                    src={process6}
                    className="m-auto mt-3"
                    style={{ height: "4rem", width: "5rem" }}
                  />
                  <div className="card body" id="webcard12">
                    <h5 style={{ textAlign: "center", margin: 25 }}>
                      Error/Bug Fixing:
                    </h5>
                    <div className="underline"></div>
                    <p style={{ textAlign: "center", padding: "15px" }}>
                      Anomalies and flaws can manifest in the functionality or
                      design of a website, necessitating professional
                      intervention. This is where a reputable web design agency
                      intervenes. The process of identifying and rectifying
                      these issues is known as bug rectification, with the
                      objective of ensuring uninterrupted website operation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row"></div>
        </div>
        <div className="container  my-5">
          <div className="row">
            <div className="col-sm-6">
              <div data-aos="fade-right">
                <h3 className="sm mt-5">Web Streamlining Strategy</h3>
                <p className="tt text-justify mt-3">
                  A web optimization is a comprehensive approach aimed at
                  enhancing website performance, user experience, and
                  visibility.
                </p>

                <ul>
                  <li>Fully Adaptive</li>
                  <br />
                  <li>Device Evaluation</li>
                  <br />
                  <li>Sleek & Contemporary</li>
                  <br />
                  <li>Superlative Experience</li>{" "}
                </ul>
              </div>
            </div>
            <div className="col-sm-6">
              <div data-aos="fade-left">
                <img
                  id="clientpages"
                  src={website_stramling}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="hhhh text-center">
            <h2>Why Choose Our Website Development Services</h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
            <p className="wn mt-4">
              <div data-aos="fade-up" data-aos-duration="3000">
                We serve as your trusted collaborators in transforming digital
                aspirations into extraordinary realities. As a preeminent
                website development company, we transcend the roles of mere
                designers and developers. Amidst the dynamic realm of web
                solutions, we stand as a beacon of ingenuity and technical
                prowess, boasting a track record of delivering exceptional
                outcomes. Our team of experts is wholeheartedly dedicated to
                providing top-tier web development services.
              </div>
            </p>
          </div>
          <div className="row g-3">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title text-primary">
                    Expert Team of Designers and Developers
                  </h5>
                  <p className="card-text">
                    Every endeavour, including Web Development Services, is
                    entrusted to the adept professionals at Doaguru IT
                    Solutions, in collaboration with the Best Digital Marketing
                    Company and Top IT companies, who bring a wealth of
                    knowledge and inventiveness, ensuring that your website not
                    only meets but surpasses your expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title text-primary">
                    Quality Craftsmanship Aligned with Your Precise Requisites
                  </h5>
                  <p className="card-text">
                    As the best website design company, we approach every
                    undertaking with a commitment to delivering high-caliber
                    work that meticulously adheres to your stipulations,
                    recognizing the uniqueness of each project.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title text-primary">
                    {" "}
                    Cost-Efficiency and Adaptability
                  </h5>
                  <p className="card-text">
                    We firmly believe that first-rate web design and development
                    from our top-tier web development company should not be
                    exorbitantly priced. Your investment is sure to yield
                    substantial returns owing to our dedication to
                    affordability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title text-primary">
                    Incorporating the Latest Design Fashions and Technologies
                  </h5>
                  <p className="card-text">
                    Remaining attuned to the latest trends and technologies is
                    paramount in the constantly evolving field of web design and
                    development. Our position at the forefront of design
                    innovation is a source of great pride.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title text-primary">
                    Customized Solutions
                  </h5>
                  <p className="card-text">
                    We acknowledge the individuality of each project,
                    necessitating a customized approach. In light of this, we
                    take immense satisfaction in partnering with a top-tier
                    social media marketing agency to furnish fully tailored
                    solutions that cater to your distinct needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title text-primary">
                    {" "}
                    Affordability and Flexibility
                  </h5>
                  <p className="card-text">
                    We firmly assert that top-grade website design and
                    development should not be prohibitively expensive. Your
                    investment in our top-tier web development company is
                    certain to be highly rewarding thanks to our unwavering
                    commitment to affordability.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-3 g-4 mt-1"></div>
        </div>
        <div className="container">
          <h3 className="text-center mt-5">Technology we use</h3>
          <div
            className="underline mx-auto"
            style={{
              height: 3,
              width: "4rem",
              backgroundColor: "#34495E",
              marginTop: 15,
              marginBottom: 30,
            }}
          ></div>

          <div className="container-2">
            <div className="card-group my-5" id="group">
              <div
                className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://legacy.reactjs.org/docs/getting-started.html"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  {" "}
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={react1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://angular.io/docs"
                  target="_blank"
                  rel="norefferel"
                  onClick={handleFooterLink}
                >
                  {" "}
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={angular1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://docs.flutter.dev/"
                  target="_blank"
                  rel="norefferel"
                  onClick={handleFooterLink}
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={flutter1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://www.php.net/docs.php"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  {" "}
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={php1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://docs.python.org/3/"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  {" "}
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={python1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://wordpress.org/documentation/"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={wp1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
            </div>
            <div className="card-group my-5" id="group">
              <div
                className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://developer.mozilla.org/en-US/docs/Web/HTML"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={html1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://devdocs.io/css/"
                  target="_blank"
                  rel="norefferel"
                  onClick={handleFooterLink}
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={css1}
                    alt="Card image cap"
                  />
                </Link>
              </div>

              <div
                className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://devdocs.io/javascript/"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  {" "}
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={js1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://api.jquery.com/"
                  target="_blank"
                  rel="norefferel"
                  onClick={handleFooterLink}
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={jquery1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://getbootstrap.com/docs/5.2/getting-started/introduction/"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={bootstrap1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
              <div
                className="card mx-4  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cardres"
              >
                <Link
                  to="https://dev.mysql.com/doc/"
                  onClick={handleFooterLink}
                  target="_blank"
                  rel="norefferel"
                >
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={my1}
                    alt="Card image cap"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container" id="last">
          <div className="hero-section text-center mt-5">
            <h2 style={{ paddingTop: "4rem", color: "#155ea3" }}>
            Unleash Your Digital Potential Today with the Best Software Company in Jabalpur

            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
            <p className="mt-4">
            Ready to take your business to new heights online? Partner with the leading software company in Jabalpur, recognized as the top software company in Jabalpur, to maximize your digital potential. Through the collaboration of Doaguru IT Solutions and the renowned digital marketing agency, Best Digital Boost, we are here to empower your brand and enhance your online presence.


            </p>
            <button
              type="button"
              className="btn   mb-3"
              style={{ color: "white" }}
            >
              <Link
                to="/contact_us"
                onClick={handleFooterLink}
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Enquire Now
              </Link>
            </button>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </div>
  );
}

export default Web;

const Wrapper = styled.div`
  .heading h1 {
    display: flex;
    padding: 30px;
    justify-content: center;
    font-size: 40px;
  }

  .col-6 {
    padding: 20px;
  }

  .container li {
    font-weight: 600;
  }
  .container .p {
    text-align: center;
    color: black;
    font-weight: 500;
    font-size: 20px;
  }
  .list {
    padding-left: 50px;
    padding-bottom: 30px;
  }
  #cont {
    height: 33rem;

    @media screen and (max-width: 768px) {
      height: 53rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 52rem;
    }
  }
  #row1 {
    margin-left: 5rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  #clientpages {
    margin-top: 1rem;
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 27rem;
      margin-top: 0rem;
    }
  }
  #wwwhero {
    @media screen and (max-width: 768px) {
      margin-left: -2rem;
      margin-top: 3rem;
    }
  }
  #cardres {
    border-radius: 2rem;
    @media screen and (max-width: 768px) {
      height: 14rem;
      width: 14rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1080px) {
      height: 5rem;
      border-radius: 1rem;
    }
  }
  #group {
    @media screen and (max-width: 768px) {
      margin-left: 3.2rem;
    }
  }
  #webheropage {
    margin-top: 1rem;
    margin-left: 5rem;
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: -2rem;
      height: 33rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 28rem;
      margin-top: -2rem;
      margin-left: 7.5rem;
    }
  }
  #webcard12 {
    height: 20rem;
    border: none;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 21rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  h3 {
    font-size: 28px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .ws {
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;
