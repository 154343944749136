import React from "react";
import styled from "styled-components";
import image2 from "../components/images/about3.webp";
import Finalnav from "./Homepages/Finalnav";
import { Link } from "react-router-dom";
import value from "../components/images/value.webp";
import mission from "../components/images/mission.webp";
import vission from "../components/images/vision.webp";
import bgabout from "../components/images/bgabout.webp";
import heroabout from "../components/images/heroabout.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "./Homepages/Footer";

const About = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Finalnav />

      <Helmet>
        <title>Best Online marketing agency-About DOA Guru
</title>
        <meta
          name="description"
         content="Unlock the Secrets of Success with the Best Online Marketing Agency - All You Need to Know About DOA Guru's Expertise!"/>
        <link rel="canonical" href="https://doaguru.com/about_us"/>
      

      </Helmet>

      <Wrapper>
        <Breadcrumb />
  
           
           <div
          className="container-fluid"
          id="cont7"
          style={{
            backgroundImage: `url(${bgabout})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row67">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
              <h1 className="ws text-white" style={{ marginBottom: "1rem" }}>
              About DOAGuru
              </h1>
           
              <p className="pdd text-white mt-4">

              Welcome to our DOAGuru InfoSystems, dedicated to marketing and IT solutions. We are a team of individuals, with expertise in assisting businesses in establishing their presence and engaging their desired audience by employing efficient digital marketing tactics.
              </p>
              <button
                type="button"
                className="btn  mt-4"
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12" id="heroimg">
              <img
                src={heroabout}
                className="img3 d-block"
                alt="..."
                id="fullscreen"
              />
            </div>
          </div>
        </div>

        <section
          className="section"
          style={{ paddingTop: 40, paddingBottom: 40 }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 style={{ color: "#34495E" }}>Our Company</h2>
                <div
                  className="underline mx-auto"
                  style={{
                    height: 3,
                    width: "4rem",
                    backgroundColor: "#34495E",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <p>
                  Welcome to Doaguru IT Solutions, your reliable partner in the realms of digital marketing, web designing, and IT solutions. Our team comprise of seasoned professionals committed to enhance the digital footprint of your business. With over a decade of industry experience, we have collaboratively worked with numerous clients worldwide, consistently delivering exceptional results in IT and marketing endeavours.
                  </p>
                </div>
                <button type="button" className="btn  mt-5">
                  <Link
                    to="/portfolio"
                    onClick={handleFooterLink}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                     Read on
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="section bg-c-light">
          <div className="container">
            <div className="row mt-3">
              <div className="col-md-12  text-center mb-4">
                <h2 style={{ color: "#34495E" }}>Vision, Mission and Value</h2>
                <div
                  className="underline mx-auto"
                  style={{
                    height: 3,
                    width: "4rem",
                    backgroundColor: "#34495E",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>
              </div>

              <div className="col-md-4 text-center">
                <div data-aos="flip-left">
                  <img src={vission} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Our Vision</h5>

                  <p className="par-text">
                  As a Best IT Company, Our aspiration is to establish ourselves as the Best digital Marketing Agency and  Web Design Agency. Our ethos revolves around the delivery of exceptional services and the cultivation of enduring client relationships.

                  </p>
                </div>
              </div>
              <div className="col-md-4 text-center">
                <div data-aos="flip-left">
                  <img src={mission} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Our Mission</h5>
                  <p className="par-text">
                  Our mission is to empower businesses to thrive in the digital realm by furnishing them with tailor-made solutions that cater to their unique needs. We recognize that each business possesses its distinctive traits.

                  </p>
                </div>
              </div>
              <div className="col-md-4 text-center">
                <div data-aos="flip-left">
                  <img src={value} alt="" style={{ height: "4rem" }} />
                  <h5 className="hhhh mt-4">Our Core Value</h5>
                  <p className="par-text">
                  At the heart of our operations is a commitment to prioritising the requirements of our customers and building robust connections grounded in mutual satisfaction and trust. Our dedication to social responsibility underscores our values.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section bg-c-light">
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-md-12 mb-5 text-center">
                <h2 style={{ color: "#34495E" }}>Why Choose Us?</h2>
                <div
                  className="underline mx-auto"
                  style={{
                    height: 3,
                    width: "4rem",
                    backgroundColor: "#34495E",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>
              </div>

              {/* <div className="col-md-6 mt-3 ">
                <div className="card shadow">
                  <div className="card body" id="cardrespon">
                    <h5 style={{ textAlign: "center", margin: 20 }}>
                      Timely Delivery
                    </h5>
                   
                    <p style={{ textAlign: "center",padding:"15px" }}>
                    Timely Delivery: We take immense pride in our unwavering dedication to ensuring the timely completion of all our website projects. In the fast-paced world of IT, punctuality is paramount, and we fully comprehend its significance.


                    </p>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-6 mt-3 ">
                <div className="card shadow">
                  <div className="card body" id="cardrespon">
                    <h5 style={{ textAlign: "center", margin: 20 }}>
                      Competitive Pricing
                    </h5>
                   
                    <p style={{ textAlign: "center",padding:"15px" }}>
                    Competitive Pricing: We derive satisfaction from being your preferred source for premium IT solutions, all at the most economical rates. Our commitment to affordability never compromises the quality of our services.

                    </p>
                  </div>
                </div>
              </div> */}

              <div className="col-md-6 mt-3">
                <div className="card shadow">
                  <div className="card body" id="cardrespon">
                    <h5 style={{ textAlign: "center", margin: 20 }}>
                      Uncompromising Quality
                    </h5>
                   
                    <p style={{ textAlign: "center" ,padding:"15px"}}>
                    Our IT solutions are founded on a bedrock of quality, not merely meeting industry standards but surpassing them. Employing the latest industry best practices and cutting-edge technologies, we ardently pursue excellence across all facets of our services.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <div className="card shadow">
                  <div className="card body" id="cardrespon">
                    <h5 style={{ textAlign: "center", margin: 20 }}>
                      Long-Term Support
                    </h5>
                   
                    <p style={{ textAlign: "center",padding:"15px"}}>
                    In our pursuit of ensuring the resilience and growth of your IT infrastructure in tandem with your business, we function as an extension of your company, always available to assist. Our dedication to nurturing enduring client relationships entails ongoing support and assistance.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Wrapper>
    </>
  );
};
export default About;
const Wrapper = styled.div`
  #about-id {
    margin-top: 19rem;
    text-align: center;
    @media screen and (max-width: 768px) {
      margin-top: 12rem;
    }
  }
  #cardsh {
    text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
      0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
      0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
      0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
      0 20px 20px rgba(0, 0, 0, 0.15);
  }

  #image-height {
    height: 30rem;
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      height: 35rem;
    }
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
   
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
   
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size:1.2rem;
   
    }
  }
  #cardrespon {
    height: 15rem;
    @media screen and (max-width: 768px) {
      height: 20rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  #cont7 {
    height: 27rem;

    @media screen and (max-width: 768px) {
      height: 46rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 55rem;
    }
  }
  #row67 {
    margin-left: 3rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  .img3 {
    margin-top: 4rem;
    width: 85%;
    margin-left: 4rem;
    @media screen and (max-width: 768px) {
           width: 100%;
           margin-left: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 3rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: 1.5rem;
    width: 60%;
      margin-left: 10rem;
    }
  }
 
`;
