import React from "react";
import { styled } from "styled-components";

import aa from "../../components/images/aa.webp";
import amh from "../../components/images/amh.webp";
import anishque from "../../components/images/anishque.webp";
import cios from "../../components/images/cios.webp";
import fignter from "../../components/images/fignter.webp";
import hcabaj from "../../components/images/hcabaj.webp";
import nextgen from "../../components/images/nextgen.webp";
import bala from "../../components/images/bala.webp";
import chandra from "../../components/images/chandra.webp";
import studyverse from "../../components/images/studyverse.webp";
import vinasia from "../../components/images/vinasia.webp";
import den from "../../components/images/den.webp";
import devjilogo from "../../components/images/devjilogo.webp";
import flip from "../../components/images/flip.webp";
import incredible from "../../components/images/incredible.webp";
import jj from "../../components/images/jj.webp";
import petayu from "../../components/images/petayu.webp";
import princeviraj from "../../components/images/princeviraj.webp";
import se from "../../components/images/se.webp";
import shadivivah from "../../components/images/shadivivah.webp";
import subhmed from "../../components/images/subhmed.webp";
import fornear from "../../components/images/fornear.webp";
import sanjulogo from "../../components/images/sanjulogo.webp";
import gala from "../../components/images/gala.webp";
import swa from "../../components/images/swa.webp";
import chathanya from "../../components/images/chathanya.webp";
import kaya from "../../components/images/jsss150.webp";
import ks from "../../components/images/ks.webp";
import hdr from "../../components/images/woj150.webp";
import bharma from "../../components/images/bharma.webp";

import SDH from "../../components/images/ClientLogo/SDH.png";
import OneRealty from "../../components/images/ClientLogo/OneRealty.png";
import RamAc from "../../components/images/ClientLogo/RamAca.png";
import JanJyoti from "../../components/images/ClientLogo/JanJyoti.png";

function Carousel() {
  const imageGroups = [
    [aa, amh, anishque, cios, fignter],
    [SDH, RamAc, OneRealty, JanJyoti, chathanya],
    [hcabaj, nextgen, bala, chandra, studyverse],
    [vinasia, den, devjilogo, flip, incredible],
    [jj, petayu, princeviraj, se, shadivivah],
    [subhmed, fornear, sanjulogo, gala, swa],
    [kaya, ks, hdr, bharma]
  ];

  const renderCarouselItem = (images, isActive = false) => (
    <div className={`carousel-item ${isActive ? "active" : ""}`}>
      <div className="card-group">
        {images.map((src, idx) => (
          <div key={idx} className="card border-0 m-auto flex align-items-center">
            <img src={src} className=" " alt="Client Logo" loading="lazy" style={{
              background: 'rgb(0 0 0 / 0%)',
              borderRadius: '16px',
              boxShadow: 'rgb(69 74 100 / 77%) 0px 4px 30px',
              backdropFilter: 'blur(4.9px)',
              WebkitBackdropFilter: 'blur(4.9px)',
              border: '1px solid rgba(206, 206, 234, 0.07)',
            }} />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Wrapper>
      <div className="container-fluid pt-5">
        <h2 className="hp text-center text-light mb-4"> Meet Our Happy Clients </h2>
        <div className="underline mx-auto"></div>

        <div
          id="carouselExampleIndicators"
          className="carousel slide carousel-fade"
          style={{ height: '16rem' }}
          data-bs-ride="carousel"
          data-bs-interval="3000"
        >
          <div className="carousel-indicators" >
            {imageGroups.map((_, idx) => (
              <button
                key={idx}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={idx}
                className={idx === 0 ? "active" : ""}
                aria-label={`Slide ${idx + 1}`}
              ></button>
            ))}
          </div>

          <div className="carousel-inner">
            {imageGroups.map((group, idx) => renderCarouselItem(group, idx === 0))}
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            style={{ width: '2rem' }}
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            style={{ width: '2rem' }}
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </Wrapper>
  );
}

export default Carousel;

const Wrapper = styled.div`
  background: #002c63;

  .underline {
    height: 3px;
    width: 10rem;
    background-color: #86ff68;
    margin: 10px auto 30px;
  }

  .card {
    background: #002c63;
  }

  img {
    width: 12rem;
    border-radius: 2rem;
    background: #0d376b;
    box-shadow: 0px 10px 15px 19px rgba(13, 55, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(1);
  }
`;
