import AOS from "aos";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import blog8 from "../images/performblog.png";
import Finalnav from "../../page/Homepages/Finalnav";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";
import Footer from "../../page/Homepages/Footer";

function Blog8() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <Finalnav />
      <Wrapper>
        <Breadcrumb />
        <div className="container">
          <div className="container">
            <h2 className="mt-5 text-center">
              The Future of SEO: Predictions and Strategies for 2024
            </h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div data-aos="fade-right">
                  <img src={blog8} alt="" className="frontimg rounded" />
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div data-aos="fade-left">
                  <p>
                    In today's world of online business, companies are always
                    looking for ways to make the most of their online presence
                    and achieve tangible results. Two strategies that often come
                    into play are{" "}
                    <Link
                      to="/"
                      target="_blank"
                      className=" text-decoration-none"
                    >
                      performance marketing and digital marketing
                    </Link>
                    . Although these terms are sometimes used interchangeably
                    they represent approaches, to achieving marketing goals. Let
                    us explore the distinctions between performance marketing
                    and digital marketing and how businesses can benefit from
                    each.
                  </p>
                </div>
              </div>

              <div className="col-lg-12  mt-3">
                <div data-aos="fade-left">
                  <h3>What is Digital Marketing?</h3>
                  <p>
                    Digital marketing encompasses a range of tactics to promote
                    products or services through digital channels. This includes
                    strategies such as search engine optimization (SEO) content
                    marketing, social media marketing, email marketing, and
                    more. The main objective of marketing is to create brand
                    awareness generate leads and foster customer engagement,
                    across digital platforms.
                  </p>
                </div>
                <hr />
              </div>
              {isShowMore && (
                <p>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>Understanding Performance Marketing</h3>
                      <p>
                        Performance marketing, in contrast, is a results-driven
                        approach that focuses on outcomes and return on
                        investment (ROI). Unlike advertising methods where
                        businesses pay upfront for ad placement performance
                        marketing operates on a pay-for-performance basis. This
                        implies that advertisers are only charged when specific
                        actions, such, as clicks, leads, or sales are attained.
                      </p>
                    </div>
                    <hr />
                  </div>
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>
                        Differences, between Performance Marketing and Digital
                        Marketing
                      </h3>
                      <p>
                        <span className="th">Pricing Model: </span>When it comes
                        to pricing digital marketing usually requires costs for
                        advertising space or creating campaigns of the outcomes.
                        On the other hand, performance marketing focuses on
                        paying for results achieved such as clicks, conversions,
                        or acquisitions.
                      </p>
                      <p>
                        <span className="th">
                          Measurability and Accountability:{" "}
                        </span>{" "}
                        <Link
                          to="/"
                          target="_blank"
                          className=" text-decoration-none"
                        >
                          Performance marketing
                        </Link>{" "}
                        offers measurability and accountability compared to
                        digital marketing methods. Advertisers can. Analyze
                        real-time performance metrics allowing for optimization
                        and targeting based on campaign performance.
                      </p>
                      <p>
                        <span className="th">
                          Targeting and Personalization:{" "}
                        </span>{" "}
                        Both{" "}
                        <Link
                          to="/"
                          target="_blank"
                          className=" text-decoration-none"
                        >
                          digital marketing and performance marketing
                        </Link>{" "}
                        utilize data-driven targeting and personalization
                        techniques. However, performance marketing tends to
                        employ strategies to reach specific audiences based on
                        their behavior, interests, and demographics. This
                        optimization of campaign performance can yield a return
                        on investment (ROI).
                      </p>
                      <span className="th">Risk and Reward: </span>In marketing,
                      businesses take on the risk of advertising costs in hopes
                      of achieving desired outcomes. In contrast, performance
                      marketing mitigates the risk by requiring payment, for
                      results. However, if campaigns perform well under this
                      model the potential rewards can be significant.
                    </div>
                  </div>
                  <hr />

                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>
                        The Benefits of Different Approaches, for Businesses
                      </h3>
                      <p>
                        <span className="th">Digital Marketing: </span>When
                        building brand awareness reaching an audience and
                        nurturing relationships with customers digital marketing
                        is highly effective. It sets the groundwork for
                        establishing a presence and engaging with target
                        audiences on digital platforms.
                      </p>
                      <p>
                        <span className="th">Performance Marketing: </span>For
                        businesses aiming to achieve actions and measurable
                        results like website visits, lead generation, or sales
                        conversions, performance marketing is the way to go. It
                        provides control over advertising expenses. Allows for
                        precise targeting to maximize return, on investment
                        (ROI).
                      </p>
                      <p>
                        <span className="th">
                          Targeting and Personalization:{" "}
                        </span>{" "}
                        Both digital marketing and performance marketing utilize
                        data-driven targeting and personalization techniques.
                        However, performance marketing tends to employ
                        strategies to reach specific audiences based on their
                        behavior, interests, and demographics. This optimization
                        of campaign performance can yield a return on investment
                        (ROI).
                      </p>
                      <span className="th">Risk and Reward: </span>In marketing,
                      businesses take on the risk of advertising costs in hopes
                      of achieving desired outcomes. In contrast, performance
                      marketing mitigates the risk by requiring payment, for
                      results. However, if campaigns perform well under this
                      model the potential rewards can be significant.
                    </div>
                  </div>
                  <hr />
                  <div className="col-lg-12  mt-3">
                    <div data-aos="fade-left">
                      <h3>
                        Utilizing Both Approaches, with DOAGuru InfoSystems
                      </h3>
                      <p>
                        DOAGuru InfoSystems, renowned as{" "}
                        <Link
                          to="/Performance-Marketing-vs-Digital-Marketing:-Understanding-the-Key-Differences"
                          target="_blank"
                          className=" text-decoration-none"
                        >
                          {" "}
                          the best digital marketing agency in Jabalpur
                        </Link>{" "}
                        specializes in developing comprehensive marketing
                        strategies tailored to meet the unique goals and
                        objectives of each client. Whether it involves
                        implementing marketing tactics to enhance brand
                        visibility or executing performance marketing campaigns
                        to drive conversions we possess the expertise and
                        resources to deliver favorable outcomes.
                      </p>
                      <p>
                        At{" "}
                        <Link
                          to="/"
                          target="_blank"
                          className=" text-decoration-none"
                        >
                          {" "}
                          DOAGuru InfoSystems
                        </Link>{" "}
                        businesses can leverage both marketing and performance
                        marketing strategies effectively to accomplish their
                        desired goals. Our team employs analytics,
                        state-of-the-art technologies, and industry best
                        practices to optimize campaign performance and maximize
                        return on investment (ROI).
                      </p>
                      <p>
                        In summary, while digital marketing and performance
                        marketing serve purposes they are both elements of a
                        well-rounded marketing strategy. By comprehending the
                        differences, between these two approaches and utilizing
                        them strategically businesses can effectively reach
                        their target audiences while generating results.
                        Collaborating with{" "}
                        <Link
                          to="/"
                          target="_blank"
                          className=" text-decoration-none"
                        >
                          DOAGuru InfoSystems
                        </Link>{" "}
                        guarantees that businesses will benefit from our
                        expertise and assistance in navigating the complexities
                        of the landscape while successfully achieving their
                        marketing objectives.
                      </p>
                    </div>
                  </div>
                </p>
              )}

              <button
                onClick={toggleReadMoreLess}
                className="btn my-3"
                id="more"
              >
                {isShowMore ? "Read off" : " Read on"}
              </button>
              <button className="btn my-3" id="more">
                <Link
                  to="/blogs"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                >
                  Blogs_Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Blog8;
const Wrapper = styled.div`
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  .frontimg {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }

  .th {
    font-weight: bold;
  }
`;
