const blogsData = [
  {
    title: "10 Essential Skills Every Digital Marketer Needs to Know",
    description:
      "Unlock the secrets to success in the digital marketing arena with our comprehensive guide. Explore the core skills essential for every digital marketer to thrive. From SEO mastery to content strategy, dive into the 10 fundamental skills that will propel your digital marketing expertise to new heights.",
    link: "/10-Essential-Skills-Every-Digital-Marketer-Needs-to-Know",
    image: require("../../components/images/blog.jpeg"),
  },
  {
    title: "Optimizing Your Business: Because Google Can't Read Your Mind... Yet",
    description:
      "Enhance your business's digital presence with strategic optimization techniques. From SEO to user-friendly design, we'll ensure your online visibility speaks the language of success, even before Google can read your mind.",
    link: "/Optimizing-Your-Business-Because-Google-Can't-Read-Your-Mind-Yet",
    image: require("../../components/images/blogimg2.webp"),
  },
  {
    title: "Email Marketing: Because Your Inbox Was Feeling Lonely",
    description:
      "Dive into engaging articles that breathe life into your inbox, offering strategies to boost engagement, craft compelling content, and maximize the potential of this powerful marketing tool. Join us on this digital journey to make your emails stand out in the crowd!",
    link: "/Email-Marketing-Because-Your-Inbox-Was-Feeling-Lonely",
    image: require("../../components/images/blogimg3.webp"),
  },
  {
    title: "Unveiling the Secrets of the Best Graphic Designing Company for Your Creative Dreams!",
    description:
      "Discover our vibrant blog - a treasure trove of insights, tips, and trends in the world of email marketing. Dive into engaging articles that breathe life into your inbox, offering strategies to boost engagement, craft compelling content, and maximize the potential of this powerful marketing tool.",
    link: "/Unveiling-the-Secrets-of-the-Best-Graphic-Designing-Company-for-Your-Creative-Dreams!",
    image: require("../../components/images/blog4.webp"),
  },
  {
    title: "Best Graphic Designing Company in Jabalpur: Elevate Your Brand Presence",
    description:
      "Elevate your brand to new heights with the best graphic designing company in Jabalpur. Our expert team combines creativity and precision to craft visually stunning designs that leave a lasting impression.",
    link: "/Best-Graphic-Designing-Company-in-Jabalpur-Elevate-Your-Brand-Presence",
    image: require("../../components/images/blog5.webp"),
  },
  {
    title: "Cybersecurity Essentials: Protecting Your Business in a Digital Age",
    description:
      "In today's era, the need for cybersecurity measures is more crucial than ever. As businesses increasingly rely on platforms, the risk of cyber threats becomes a concern. DOAGuru InfoSystems, renowned as the best digital marketing company in Jabalpur, understands this need.",
    link: "/Cybersecurity-Essentials-Protecting-Your-Business-in-a-Digital-Age",
    image: require("../../components/images/blog6.webp"),
  },
  {
    title: "The Future of SEO: Predictions and Strategies for 2024",
    description:
      "In the dynamic world of digital marketing, peering into the future to anticipate trends and adapt strategies is crucial for staying ahead. As we approach 2024, the landscape of search engine optimization (SEO) is on the brink of significant evolution.",
    link: "/The-Future-of-SEO:-Predictions-and-Strategies-for-2024",
    image: require("../../components/images/blogsimage.jpg"),
  },
  {
    title: "Performance Marketing vs. Digital Marketing: Understanding the Key Differences",
    description:
      "In today's world of online business, companies are always looking for ways to make the most of their online presence and achieve tangible results. Two strategies that often come into play are performance marketing and digital marketing.",
    link: "/Performance-Marketing-vs-Digital-Marketing:-Understanding-the-Key-Differences",
    image: require("../../components/images/performblog.png"),
  },
  {
    title: "Boost Your Online Presence: Best SEO Company in Madhya Pradesh",
    description:
      "In today's era, establishing an online presence is essential for any business looking to succeed in the competitive market environment. With websites competing for attention, how can yours make a mark? The key lies in SEO (Search Engine Optimization).",
    link: "/Best-SEO-company-in-Madhya-pradesh",
    image: require("../../components/images/blog9.jpg"),
  },
  {
    title: "The Best IT Company in Jabalpur - DOAGuru InfoSystems",
    description:
      "In the bustling realm of Information Technology within Jabalpur, there’s one name that resonates with excellence and innovation - DOAGuru InfoSystems. Established with a vision to redefine software solutions, DOAGuru has solidified its position as the leading IT company in Jabalpur.",
    link: "/The-Best-IT-Company-in-Jabalpur-DOAGuru-Infosystems",
    image: require("../../components/images/blog10.jpg"),
  },
  {
    title: "Best Software Development Company in Jabalpur: DOAGuru InfoSystems",
    description:
      "Welcome to DOAGuru InfoSystems, recognized as the best software company in Jabalpur. In this blog, we'll explore why DOAGuru InfoSystems stands out as the leading software development firm in Jabalpur and how we can help businesses like yours thrive in the digital age.",
    link: "/Best-Software-Development-Company-in-Jabalpur",
    image: require("../../components/images/Blog11.png"),
  },
  {
    title: "The Best Digital Marketing Company in Madhya Pradesh",
    description:
      "At DOAGuru InfoSystems, we pride ourselves on being the best digital marketing company in Madhya Pradesh. Our commitment to excellence, innovative strategies, and customer-centric approach make us the preferred choice for businesses looking to enhance their online presence.",
    link: "/Best-Digital-Marketing-Company-in-Madhya-Pradesh",
    image: require("../../components/images/Blog12.jpg"),
  },
  {
    title: "Ultimate Dental Clinic Practice Management Software",
    description:
      "Managing a dental practice is a complex process that requires exceptional precision and efficiency. Enter Dental Guru, the ultimate dental clinic practice management software, designed to streamline operations and enhance patient care.",
    link: "/Ultimate-Dental-Practice-Management-Software",
    image: require("../../components/images/dentalGuruBlog.jpg"),
  },
  {
    title: "AI in Digital Marketing: Revolutionizing the Industry with DOAGuru IT Solutions",
    description:
      "The rise of artificial intelligence (AI) is reshaping industries globally, and digital marketing is no exception. AI is transforming how businesses reach, engage, and retain customers, offering personalized experiences and . . .",
    link: "/ai-in-digital-marketing",
    image: require("../../components/images/BlofAiImage.jpg"),
  },
  {
    title: "Can You Get a Digital Marketing Job Without Any Experience?",
    description:
      "Kickstart your career in digital marketing with no prior experience! Learn essential skills, explore free resources, and build a strong foundation with expert guidance from DOAGuru InfoSystem, the Best Digital Marketing Company in Jabalpur...",
    link: "/Can-You-Get-a-Digital-Marketing-Job-Without-Any-Experience-?",
    image: require("../../components/images/Blog15img.jpg"),
  },
  {
    title: "What is Digital Marketing? A Comprehensive Guide by DOAGuru InfoSystems",
    description:
      "In today’s fast-paced, tech-savvy world, businesses have shifted their focus from traditional marketing strategies to digital marketing. But what exactly is digital marketing? Why is it so important, and how can businesses leverage ....",
    link: "/What-is-Digital-Marketing-A-Comprehensive-Guide-by-DOAGuru-InfoSystems",
    image: require("../../components/images/Blog16img.jpg"),
  },
  {
    title: "10 Skills You Need to Become a Digital Marketing Manager",
    description:
      "In today’s fast-paced digital world, businesses rely heavily on digital marketing to grow and thrive. A Digital Marketing Manager plays a pivotal role in strategizing and executing campaigns that drive brand awareness, customer engagement, and revenue growth....",
    link: "/10-Skills-You-Need-to-Become-a-Digital-Marketing-Manager",
    image: require("../../components/images/Blog17img.jpg"),
  },
  {
    title: "How to Create an AI Digital Marketing Strategy?",
    description:
      "Artificial Intelligence (AI) has revolutionized digital marketing, enabling businesses to understand their audience, automate processes, and deliver highly personalized experiences. Crafting an AI-driven digital marketing strategy is not just a trend but a necessity for....",
    link: "/How-to-Create-an-AI-Digital-Marketing-Strategy?",
    image: require("../../components/images/Blog18img.jpg"),
  },
  {
    title: "Digital Marketing Skills, Career Opportunities, and Success Tips for 2025",
    description:
      "Discover essential digital marketing skills, career opportunities, and strategies to excel in 2025. Learn how to stay ahead in this dynamic field with actionable insights....",
    link: "/Digital-Marketing-Career-Opportunities-in-2025",
    image: require("../../components/images/Blog19img.jpg"),
  },




];

export default blogsData;
