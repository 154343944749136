import React, { useState } from "react";
import styled from "styled-components";
import aa from "../components/images/aa.webp";
import amh from "../components/images/amh.webp";
import anil from "../components/images/anil.webp";
import bala from "../components/images/bala.webp";
import chandra from "../components/images/chandra.webp";
import anishque from "../components/images/anishque.webp";
import cios from "../components/images/cios.webp";
import fignter from "../components/images/fignter.webp";
import hcabaj from "../components/images/hcabaj.webp";
import nextgen from "../components/images/nextgen.webp";
import sachin from "../components/images/spark.webp";
import studyverse from "../components/images/studyverse.webp";
import vinasia from "../components/images/vinasia.webp";
import den from "../components/images/den.webp";
import devjilogo from "../components/images/devjilogo.webp";
import flip from "../components/images/flip.webp";
import incredible from "../components/images/incredible.webp";
import jj from "../components/images/jj.webp";
import petayu from "../components/images/petayu.webp";
import princeviraj from "../components/images/princeviraj.webp";
import se from "../components/images/se.webp";
import shadivivah from "../components/images/shadivivah.webp";
import subhmed from "../components/images/subhmed.webp";
import Times from "../components/images/Times.webp";
import fornear from "../components/images/fornear.webp";
import sanjulogo from "../components/images/sanjulogo.webp";
import hcabajpage from "../components/images/highcourt.webp";
import cn from "../components/images/cn.webp";
import fsp from "../components/images/fn.webp";
import jp from "../components/images/jp.webp";
import pvg from "../components/images/pvg.webp";
import svp from "../components/images/svp.webp";
import gala from "../components/images/gala.webp";
import jsss from "../components/images/jsss.webp";
import woj from "../components/images/woj.webp";
import swa from "../components/images/swa.webp";
import img10 from "../components/images/10.webp";
import img11 from "../components/images/11.webp";
import img12 from "../components/images/12.webp";
import img13 from "../components/images/13.webp";
import img15 from "../components/images/15.webp";
import img16 from "../components/images/16.webp";
import img17 from "../components/images/17.webp";
import img18 from "../components/images/18.webp";
import img19 from "../components/images/19.webp";
import img20 from "../components/images/20.webp";
import img21 from "../components/images/21.webp";
import img23 from "../components/images/23.webp";
import img24 from "../components/images/24.webp";
import bgport from "../components/images/bgport.webp";
import heroport from "../components/images/heroport.webp";
import Finalnav from "./Homepages/Finalnav";
import AOS from "aos";
// logo image import 
import Chaitanya from "../components/images/ClientLogo/Chaitanya.png";
import Chandrayu from "../components/images/ClientLogo/Chandrayu.png";
import daksh from "../components/images/ClientLogo/daksh.png";
import ENT from "../components/images/ClientLogo/ENT.png";
import IVF from "../components/images/ClientLogo/IVF.png";
import JBPHos from "../components/images/ClientLogo/JabalpurHospital.png";
import JanJyoti from "../components/images/ClientLogo/JanJyoti.png";
import Lari from "../components/images/ClientLogo/Lari.png";
import IVFMahakoshal from "../components/images/ClientLogo/Mahakoshal.png";
import Milton from "../components/images/ClientLogo/Milton.png";
import NiceHome from "../components/images/ClientLogo/NiceHome.png";
import OneRealty from "../components/images/ClientLogo/OneRealty.png";
import RamAc from "../components/images/ClientLogo/RamAca.png";
import RohiniDent from "../components/images/ClientLogo/RohiniDent.png";
import SDH from "../components/images/ClientLogo/SDH.png";


import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import PortfolioLogin from "../components/PortfolioLogin";

import ".././App.css";
import axios from "axios";
import Slidebtn from "../components/Slidebtn";
import Footer from "./Homepages/Footer";

export const Portfolio = () => {
  let [toggleBtn, setToggelBtn] = useState(true);

  let [hideShow, setHideShow] = useState(true);

  let protecteApi = async (z) => {
    try {
      let data = await axios.post(
        `https://doaguru.com/api/auth/protected/${z}`
      );
      let { user } = data.data;
      if (user) {
        setToggelBtn(false);
      } else {
        setToggelBtn(true);
      }
    } catch (error) {
      console.log("err login api");
    }
  };

  let FechAuth = () => {
    let token = localStorage.getItem("token");
    if (token?.length > 0) {
      protecteApi(token);
    } else {
      protecteApi("null");
    }
  };

  useEffect(() => {
    FechAuth();
  }, []);

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Finalnav toggle={toggleBtn} />
      {toggleBtn ? (
        <button
          className="btnLogin px-2 py-1"
          style={{ position: `${hideShow ? "fixed" : "absolute"}` }}
          onClick={() => setHideShow((pre) => !pre)}
        >
          {hideShow ? "Close" : "Login"}
        </button>
      ) : (
        ""
      )}

      {toggleBtn ? (
        hideShow ? (
          <PortfolioLogin fachAuth={FechAuth} />
        ) : null
      ) : null}
      <>
        <Helmet>
          <title>An IT service provider company in Jabalpur, MP</title>
          <meta
            name="description"
            content="DOAGuru InfoSystems Provide Digital Marketing & Web Development & Website Designing Services At Affordable Prices. Call Now at +917440992424"
          />
          <link rel="canonical" href="https://doaguru.com/portfolio" />
        </Helmet>
        <Main>
          <Breadcrumb />
          <div className="d-flex justify-content-end px-2"></div>
          <div
            className="container-fluid m-0"
            style={{
              backgroundImage: `url(${bgport})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="row" id="row67">
              <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
                <h1 className="ws text-white" style={{ marginBottom: "1rem" }}>
                  Our Portfolio
                </h1>

                <p className="pdd text-white mt-4">
                  Boost your brand with DOAGuru InfoSystems, where happy
                  clients are our success story. Experience digital growth like
                  never before
                </p>
                <button
                  type="button"
                  className="btn  mt-4"
                  style={{ color: "white" }}
                >
                  <Link
                    to="/contact_us"
                    onClick={handleFooterLink}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    Get Started
                  </Link>
                </button>
              </div>
              <div className="col-lg-6 col-md-12" id="heroimg">
                <img
                  src={heroport}
                  className="img3 d-block"
                  alt="..."
                  id="fullscreen"
                />
              </div>
            </div>
          </div>

          <div className="container">
            <h2 className="ohc text-center mt-4">Our Happy Client</h2>
            <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 20,
                marginBottom: 30,
              }}
            ></div>
            <p className="ttt text-center" style={{ marginBottom: "4rem" }}>
              <div data-aos="fade-up" data-aos-duration="3000">
                In the realm of information technology and the digital marketing
                sphere, we stand tall, holding the esteemed position of the
                foremost entity. This encompasses the entirety of our esteemed
                clientele, individuals and enterprises alike, whom we cater to
                with bespoke solutions tailored precisely to their requisites,
                fostering the expansion and prosperity of their ventures. We
                take immense pride in knowing that they are all happy and
                satisfied with our services.
              </div>
            </p>
            <div className="card-group my-3  cards56">
              <div
                className="card mx-3  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={Lari}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={JanJyoti}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={JBPHos}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={IVFMahakoshal}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={Milton}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </div>
            <div className="card-group my-3  cards56">
              <div
                className="card mx-3  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={NiceHome}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={OneRealty}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={RamAc}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={RohiniDent}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={SDH}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </div>
            <div className="card-group my-3  cards56">
              <div
                className="card mx-3  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={IVF}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={ENT}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={daksh}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={Chandrayu}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={Chaitanya}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </div>

            <div className="card-group my-3 cards56">
              <div
                className="card cards12 mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top "
                    id="imgs1"
                    src={img10}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs1"
                    src={img11}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs1"
                    src={img12}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs1"
                    src={img13}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs1"
                    src={img15}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </div>
            <div className="card-group my-3 cards56">
              <div
                className="card cards13 mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs1"
                    src={img16}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs1"
                    src={img17}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs1"
                    src={img18}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs1"
                    src={img19}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in" className="imageoverhide">
                  <img
                    className="card-img-top"
                    id="imgs1"
                    src={img20}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </div>

            <div className="card-group my-3 cards56">
              <div
                className="card cards13 mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id=""
                    src={img24}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={img21}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={jj}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="jsss"
                    src={jsss}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards13"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id=""
                    src={woj}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </div>

            <div className="card-group my-3  cards56">
              <div
                className="card mx-3  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={cios}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={fignter}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={hcabaj}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={nextgen}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2  align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={sachin}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </div>

            <div className="card-group my-3  cards56">
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={studyverse}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={vinasia}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={den}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={anil}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2   align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={flip}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </div>
            <div className="card-group my-3  cards56">
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={incredible}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={bala}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={petayu}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={princeviraj}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={Times}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </div>
            <div className="card-group my-3  cards56">
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={subhmed}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={amh}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={fornear}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white  border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={shadivivah}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3  py-0 px-2 align-items-center justify-content-center shadow-lg bg-white  border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={se}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </div>
            <div className="card-group my-3  cards56">
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white  border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={sanjulogo}
                    alt="Card image cap "
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white  border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={gala}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white  border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={chandra}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white  border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgsw"
                    src={anishque}
                    alt="Card image cap"
                  />
                </div>
              </div>
              <div
                className="card mx-3 py-0 px-2 align-items-center justify-content-center shadow-lg bg-white  border-left"
                id="cards12"
              >
                <div data-aos="zoom-in">
                  <img
                    className="card-img-top"
                    id="imgs"
                    src={swa}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container my-5">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div data-aos="fade-right">
                  <img
                    id="clientpages1"
                    src={hcabajpage}
                    style={{ animation: "fadeIn 15s;" }}
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div data-aos="fade-left">
                  <h2 style={{ marginBottom: "1.5rem", marginTop: "1rem" }}>
                    High Court ABAJ
                  </h2>
                  <text>
                    HCABAJ: This is a community portal for lawyers of the High
                    Court of Jabalpur, which is used for making connectivity
                    between bar members of the high court. We also develop
                    mobile applications for our clients in both IOS and Android:
                  </text>
                  <br />
                  <br />
                  <br />
                  <ul>
                    <li> Subscription based payment gateway</li>
                    <li> Profile section for every user</li>
                    <li> List of all members</li>
                    <li> SMS Gateway for Admin</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container  my-5">
            <div className="row">
              <div className="col-sm-6" id="mobile">
                <div data-aos="fade-left">
                  <Link
                    to="https://ciosgovt.co.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <img id="clientpages2" src={cn} className="imgs1" />
                  </Link>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div data-aos="fade-right">
                  <Link
                    to="https://ciosgovt.co.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <h2 style={{ marginBottom: "imgs1", marginTop: "0rem" }}>
                      School Management Website
                    </h2>
                  </Link>
                  <text>
                    This website we developed for a school to automate all
                    school-related work, including admission, attendance, exams,
                    results, timetables, admit cards, fee management, school
                    staff management, library management, and lots of other
                    features.
                  </text>
                  <br />
                  <br />
                  <ul>
                    <li> Student management</li>
                    <li> Exam management</li>
                    <li> Staff management</li>
                    <li> Result Management</li>
                    <li> Online class & Home Work</li>
                    <li> library management</li>
                    <li> Account management</li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6" id="desktop">
                <div data-aos="fade-left">
                  <Link
                    to="https://ciosgovt.co.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <img id="clientpages2" src={cn} className="imgs1" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="container  my-5">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div data-aos="fade-right">
                  <Link
                    to="https://fornearservices.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <img id="clientpages3" src={fsp} className="img-fluid" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div data-aos="fade-left">
                  <Link
                    to="https://fornearservices.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <h2 id="fore">Fornear Services </h2>
                  </Link>
                  <text>
                    In crafting this online platform for procuring groceries,
                    our aim was to render the experience effortlessly convenient
                    for you. Featuring an intuitive and user-centric interface,
                    acquiring your essential provisions has been streamlined to
                    unprecedented levels of simplicity and accessibility.
                  </text>
                  <br />
                  <br />

                  <ul>
                    <li>Mobile Responsive</li>
                    <li>Payment Gateway</li>
                    <li>Multi vendor login</li>
                    <li>Inventory management</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container  my-5">
            <div className="row">
              <div className="col-lg-6 col-md-12" id="mobile">
                <div data-aos="fade-left">
                  <img src={jp} className="img-fluid" id="clientpages4" />
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div data-aos="fade-right">
                  <Link
                    to="https://janjyotieyehospital.com/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <h2>Jan Jyoti Hospital</h2>
                  </Link>
                  <text>
                    JanJyoti :- This is an informative and promotional website
                    for an eye hospital:
                  </text>
                  <br />
                  <br />

                  <ul>
                    <li> Take Appointment section</li>
                    <li> Donation section</li>
                    <li> SEO Friendly website</li>
                    <li> Result Management</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-12" id="desktop">
                <div data-aos="fade-left">
                  <Link
                    to="https://janjyotieyehospital.com/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <img src={jp} className="img-fluid" id="clientpages4" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="container  my-5">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div data-aos="fade-right">
                  <img id="clientpages5" src={pvg} className="img-fluid" />
                </div>
              </div>
              <div className="col-sm-6">
                <div data-aos="fade-left">
                  <h2 id="prince"> Prince Viraj Hotel </h2>
                  <text>
                  Prince Viraj:
                  This is a website designed for managing a hotel business virtually. It allows the administration to manage hotels and rooms efficiently. Additionally, there is a dedicated restaurant section to streamline restaurant operations. We also provide a comprehensive system to manage all aspects of restaurant workflows.
                  </text>
                  <br />
                  <br />

                  <ul>
                    <li> Booking Engine</li>
                    <li> Restaurant management</li>
                    <li> Event Management</li>
                    <li> Mobile Responsive</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container  my-5">
            <div className="row">
              <div className="col-sm-6" id="mobile">
                <div data-aos="fade-left">
                  <Link
                    to="https://shadivivah.co.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    <img id="clientpages6" src={svp} className="imgs1" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div data-aos="fade-right">
                  <Link
                    to="https://shadivivah.co.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <h2
                      style={{
                        textAlign: "left",
                        marginBottom: "1.5rem",
                        marginTop: "0rem",
                      }}
                    >
                      Shadi Vivah Metrimonial Website
                    </h2>
                  </Link>
                  <text>
                    This is a matrimonial website that allows users to make
                    their profile, view other members’ profiles, and find their
                    match here on this website. This website helps in finding
                    the perfect life partner. this web:
                  </text>
                  <br />
                  <br />

                  <ul>
                    <li> Profile Registration</li>
                    <li>Profile Searching</li>
                    <li>Admin dashboard</li>
                    <li>Payment option</li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6" id="desktop">
                <div data-aos="fade-left">
                  <Link
                    to="https://shadivivah.co.in/"
                    target="_blank"
                    rel="norefferel"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <img id="clientpages6" src={svp} className="imgs1" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Main>
      </>
      <Footer />
    </>
  );
};
export default Portfolio;

const Main = styled.div`
  text {
    text-align: center;
    margin-bottom: 1.5rem;
    line-height: 1.7;
  }
  li {
    line-height: 1.9;
  }

  #cards12 {
    border-radius: 25px;
    @media screen and (max-width: 768px) {
      height: 12rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 6rem;
    }
  }
  #cards13 {
    height: 8rem;
    border-radius: 25px;
    @media screen and (max-width: 768px) {
      height: 12rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 6rem;
    }
  }
  #cards12:hover {
    transform: scale(1.1);
  }
  #cards13:hover {
    transform: scale(1.1);
  }
  #imgs {
    width: 8rem;

    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 4.2rem;
    }
  }
  #jsss {
    height: 7.5rem;
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 5rem;
    }
  }

  #cards12:hover img {
    transition: transform 0.3s ease-in-out;
  }

  #clientpages1 {
    /* height: 27rem; */
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 29rem;
    }
  }
  #clientpages2 {
    height: 25rem;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 15rem;
    }

    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 205%;
    }
  }
  #clientpages3 {
    box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
    margin-left: -1rem;
    height: 22rem;
    @media screen and (max-width: 768px) {
      margin-left: 0rem;
      height: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 23rem;
      width: 100%;
    }
  }
  #clientpages4 {
    box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 25rem;
      width: 100%;
    }
  }
  #clientpages5 {
    height: 20rem;
    box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
    @media screen and (max-width: 768px) {
      height: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 25rem;
    }
  }
  #clientpages6 {
    height: 23rem;
    box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
    @media screen and (max-width: 768px) {
      height: 13.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 26rem;
    }
  }

  #imgsw {
    width: 8rem;
    @media screen and (max-width: 768px) {
      width: 9rem;
      height: 8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 6rem;
      height: 2rem;
    }
  }
  #fore {
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    width: 18rem;

    @media screen and (min-width: 768px) and (max-width: 1020px) {
      font-size: 2rem;
    }
  }
  #pot {
    text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
      0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
      0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
      0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
      0 20px 20px rgba(0, 0, 0, 0.15);
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  #prince {
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  #cont7 {
    height: 25rem;

    @media screen and (max-width: 768px) {
      height: 37rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 45rem;
    }
  }
  #row67 {
    margin-left: 3rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  .img3 {
    margin-top: 3rem;
    width: 70%;
    margin-left: 6rem;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-left: 0rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      margin-top: 5rem;
      width: 30rem;
      margin-left: 7.5rem;
    }
    @media screen and (min-width: 1400px) and (max-width: 3700px) {
      margin-top: 1.5rem;
      width: 60%;
      margin-left: 10rem;
    }
  }

  #mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
      margin-bottom: 1rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: block;
      margin-bottom: 1rem;
    }
  }
  #desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      display: none;
    }
  }

  .imageoverhide{
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    // background-color: darkblue;
  }
`;

{
  /* <Link
                to="https://joyseniorsecondary.ac.in/"
                target="_blank"
                rel="norefferel"
                style={{ textDecoration: "none" }}
              >  */
}
