import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Finalnav from '../Homepages/Finalnav';
import Footer from '../Homepages/Footer';
import styled from 'styled-components';

const AddToCartPage = () => {
  const [products, setProducts] = useState([]); // List of products
  const [cart, setCart] = useState([]); // Items added to cart
  const navigate = useNavigate(); // For navigation to checkout page

  useEffect(() => {
    // Fetch product list from the server
    const fetchProducts = async () => {
      const { data } = await axios.get('https://doaguru.com/api/payment/FetchProducts');
      setProducts(data);
    };
    fetchProducts();
  }, []);

  const addToCart = (product) => {
    if (!cart.find((item) => item.id === product.id)) {
      setCart([...cart, product]); // Add product to cart
    }
  };

  const handleCheckout = () => {
    navigate('/InitiateCheckoutPage', { state: { cart } }); 
  };

  return (
    <Wrapper>
    <div className="page-wrapper">
    <Finalnav />
    <div className="container mt-5">
      <h2>Products</h2>
      <ul className="list-group">
        {products.map((product) => (
          <li key={product.id} className="list-group-item d-flex justify-content-between">
            {product.name} - ₹{product.price}
            <button
              className="btn btn-success"
              onClick={() => addToCart(product)}
              disabled={cart.find((item) => item.id === product.id)}
            >
              {cart.find((item) => item.id === product.id) ? 'Added' : 'Add to Cart'}
            </button>
          </li>
        ))}
      </ul>

      {/* Show Checkout button if cart has items */}
      {cart.length > 0 && (
        <div className="mt-3 d-flex justify-content-end">
          <button className="btn btn-primary" onClick={handleCheckout}>
            Checkout
          </button>
        </div>
      )}
    </div>
    </div>
    <Footer />
    </Wrapper>
  );
};

export default AddToCartPage;

const Wrapper = styled.div`
/* Ensure full-page layout with fixed navbar and footer */
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 

}

`; 
