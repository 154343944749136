import styled from "styled-components";
import { useEffect } from "react";
import AOS from "aos";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Finalnav from "./Homepages/Finalnav";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "./Homepages/Footer";
import BlogCard from "../components/blogs/Blogcard";
import blogbg from "../components/images/blogbg.webp";
import bloghero from "../components/images/bloghero1.webp";
import blogsData from "../components/blogs/BlogData";

function Blogs() {
  useEffect(() => {
    AOS.init();  
  }, []);

  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
          Insightful Blog Articles on Software Solutions & Digital Marketing Strategies
        </title>
        <meta
          name="description"
          content="Stay updated with the latest trends and expert advice on software development, digital marketing strategies, and tech innovations. Explore informative articles on our blog at DOAGuru InfoSystems."
        />
        <link rel="canonical" href="https://doaguru.com/blogs" />
      </Helmet>
      <Breadcrumb />
      <Wrapper>
        <div
          className="container-fluid"
          id="cont7"
          style={{
            backgroundImage: `url(${blogbg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row67">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
              <h1 className="ws text-white" style={{ marginBottom: "1rem" }}>
                Unlocking Digital Success with our Digital Marketing Blogs!
              </h1>
              <p className="pdd text-white mt-4">
                Discover insights and trends at DOAGuru InfoSystems. Elevate your digital strategies with our expert content.
              </p>
              <button
                type="button"
                className="btn mt-4"
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink}
                  className="text-white text-decoration-none"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12" id="heroimg">
              <img src={bloghero} className="img3 d-block" alt="hero" />
            </div>
          </div>
        </div>
        <div className="container">
          <h2 className="text-center mt-4">List of Blogs</h2>
          <div
            className="underline mx-auto"
            style={{
              height: 3,
              width: "4rem",
              backgroundColor: "#34495E",
              marginTop: 10,
              marginBottom: 10,
            }}
          ></div>
          <div className="row mt-5 cardBox">
            {blogsData.map((blog, index) => (
              <BlogCard
                key={index}
                title={blog.title}
                description={blog.description}
                link={blog.link}
                image={blog.image}
                handleFooterLink={handleFooterLink}
              />
            ))}
          </div>
        </div>
        <Footer />
      </Wrapper>
    </>
  );
}

export default Blogs;

const Wrapper = styled.div`
  #cont7 {
    height: 25rem;

    @media screen and (max-width: 768px) {
      height: 45rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 48rem;
    }
  }
  #row67 {
    margin-left: 8rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  .frontimg {
    width: 100%;
    height: 40rem;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
     @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }
  .frontimg1 {
    width: 100%;
    height: 40rem;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  .card {
    border: none;
    margin: 1rem;

    img {
      border-radius: 10px;
      transition: 0.4s ease-in-out;
      cursor: pointer;
    }
    img:hover {
      transform: scale(1.09);
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
  #fullscreen1{
  height: 14.7rem;
  @media screen and (min-width: 768px) and (max-width: 1020px) {
     height: 14rem;
    }
  @media screen and (min-width: 1020px) and (max-width: 1600px) {
      height: 14.5rem;
    
    }
  }
  #fullscreen{
  height: 14.8rem;
  @media screen and (min-width: 768px) and (max-width: 1020px) {
     height: 11rem;
    }
  @media screen and (min-width: 1020px) and (max-width: 1600px) {
      height: 12rem;
    
    }
  }
  #fullscreen2{
  height: 16.1rem;
  @media screen and (min-width: 768px) and (max-width: 1020px) {
     height: 14rem;
    }
  @media screen and (min-width: 1020px) and (max-width: 1600px) {
      height: 16.2rem;
    
    }
  }
  #fullscreen3{
  height: 15rem;
  @media screen and (min-width: 768px) and (max-width: 1020px) {
     height: 11rem;
    }
  @media screen and (min-width: 1020px) and (max-width: 1600px) {
      height: 12rem;
    
    }
  }
  .card-text{
  padding: 5px;
}

`;
