import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Finalnav from "../Homepages/Finalnav";
import Footer from "../Homepages/Footer";
import styled from "styled-components";

const PaymentPage = () => {
  const location = useLocation(); // Access state from navigation
  const { cart, total } = location.state || { cart: [], total: 0 };
  // console.log(cart.map((item) => item.name).join(", "));

  
  const perGST = (total * 18) / 100;
  // console.log(perGST);
  
  const totalAmount = parseFloat(total) + parseFloat(perGST);
  // console.log(totalAmount);

  const ninePerGst = (perGST) / 2;
  // console.log(ninePerGst);

  // const amountInRupees = totalAmount;
const amountInRoundFigure = Math.round(totalAmount);


  // State for capturing user details
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    contact: "",
  });
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [paymentId, setPaymentId] = useState("");

  // Handle changes in input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const makePayment = async () => {
    try {
      // Validate user details
      if (!userDetails.name || !userDetails.email || !userDetails.contact) {
        alert("Please fill in all the details to proceed with the payment.");
        return;
      }
      // console.log('Product name',cart.name, amountInRoundFigure);
  
      // Fetch Razorpay Key ID
      const keyResponse = await axios.get("https://doaguru.com/api/payment/getKey");
      const razorpayKey = keyResponse.data.key;
  
      // Create an order on the backend
      const { data } = await axios.post(
        "https://doaguru.com/api/payment/createOrder",
        { amount: amountInRoundFigure, currency: "INR" }
      );
  
      const options = {
        key: razorpayKey,
        amount: data.order.amount,
        currency: data.order.currency,
        name: "DOAGuru InfoSystems",
        description: "Purchase Transaction",
        order_id: data.order.id,
        handler: async (response) => {
          setOrderId(response.razorpay_order_id);
          setPaymentId(response.razorpay_payment_id);
          
          // Send payment verification and user details to backend
          const verifyPayload = {
            orderId: response.razorpay_order_id,
            paymentId: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            userDetails,
            productName: cart.map((item) => item.name).join(", "), 
            amount: amountInRoundFigure,
          };
  
          try {
            const verifyResponse = await axios.post(
              "https://doaguru.com/api/payment/verifyPayment",
              verifyPayload
            );
  
            if (verifyResponse.data.success) {
              setPaymentStatus("success");
              alert("Payment successful and details saved!");
            } else {
              alert("Payment verification failed. Details not saved.");
            }
          } catch (error) {
            console.error("Error verifying payment:", error);
            alert("Payment verification failed. Please contact support.");
          }
        },
        prefill: {
          name: userDetails.name,
          email: userDetails.email,
          contact: userDetails.contact,
        },
        theme: {
          color: "#3399cc",
        },
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert("Failed to initiate payment. Please try again.");
    }
  };
  

  const handlePrint = () => {
    window.print(); // Prints the current page
  };

  return (
    <Wrapper>
    <div className="page-wrapper">
    <Finalnav />
    <div className="container mt-5">
      {paymentStatus === "success" ? (
        <>
          <h2>Thank You for Your Payment!</h2>
          <p>Your payment was successful.</p>
          <p>
            <strong>Order ID:</strong> {orderId}
          </p>
          <p>
            <strong>Payment ID:</strong> {paymentId}
          </p>
          <button className="btn btn-success mt-3" onClick={handlePrint}>
            Print Receipt
          </button>
        </>
      ) : (
        <>
          <h2>Complete Payment</h2>
          {cart.length > 0 ? (
            <>
              <ul className="list-group">
                {cart.map((item) => (
                  <li key={item.id} className="list-group-item">
                    {item.name} - ₹{item.price}
                  </li>
                ))}
                {cart.map((item) => (
                  <li key={item.id} className="list-group-item">
                    {item.name} - Discount Price - ₹{total}
                  </li>
                ))}
                
              </ul>
              <div className="d-flex justify-content-between align-items-center">

              <div className="m-1 p-1 border-top border-2 d-flex flex-column justify-content-end align-items-end ">
                
              <h6 className="mt-3  border-3 m-1 p-1">SGST 9% - {ninePerGst}</h6>
              <h6 className="mt-3 border-top border-3 m-1 p-1">CGST 9% - {ninePerGst}</h6>
              <h6 className="mt-3 border-top border-3 m-1 p-1">GST 18% - {perGST}</h6>
              <h3 className="mt-3 border-top border-bottom border-3 m-1 p-1">Total Amount: ₹{totalAmount}</h3>
              </div>

              
              {/* User details input form */}
              <div className="mt-3 border-3 rounded-5 border p-3" style={{ width: "60%" }}>
                <h4>Enter Your Details</h4>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={userDetails.name}
                    onChange={handleInputChange}
                    placeholder="Enter your name"
                    required
                  />
                </div>
                <div className="form-group mt-2">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={userDetails.email}
                    onChange={handleInputChange}
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="form-group mt-2">
                  <label>Contact</label>
                  <input
                    type="text"
                    className="form-control"
                    name="contact"
                    value={userDetails.contact}
                    onChange={handleInputChange}
                    placeholder="Enter your contact number"
                    required
                  />
                </div>
              </div>
              </div>
              <button className="btn btn-success mt-3" onClick={makePayment}>
                Pay ₹{totalAmount}
              </button>
            </>
          ) : (
            <p>Your cart is empty. Please add some items to proceed.</p>
          )}
        </>
      )}
    </div>
    </div>

    <Footer />
    </Wrapper>
  );
};

export default PaymentPage;
const Wrapper = styled.div`
/* Ensure full-page layout with fixed navbar and footer */
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 

}

`; 